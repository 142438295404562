@import 'material-icons/iconfont/material-icons.scss';

$button-width:  210px;
$button-height: 420px;
$button-border: 3px;
$icon-padding:  5px;
$icon-width:    $button-height - ($icon-padding * 2);
$ball-width:    18px;
$topBar-height: 35px;
$sidebar-Width: 80px;
$sidebar-Height: 100%;

.fullscreen-canvas
{
	width:          100%;
	height:         100%;
	margin:         0 auto;
	display:        flex;
	position:       absolute;
	background: #050a0f;
	z-index:        1;
	overflow:       hidden;
	pointer-events: none;
	
	
}

.fullscreen-twojs
{
	width:          100%;
	height:         100%;
	z-index:        2;
	margin:         0 auto;
	display:        flex;
	position:       absolute;
    overflow:       hidden;
    
}

.hidden
{
	visibility: hidden !important;
	opacity:    0 !important;
}

.chromeSVG
{
    fill: none;
    stroke: black;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.toolbar
{
	background: rgba(#050a0f,.6);

	height: $topBar-height;
	width: 100%;
	z-index: 90;
	position: absolute;
	top: 0;
	border-color: rgba(#050a0f,.6);
	border-width: 2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-style: none none solid none;


	.rightToolbar
	{
		margin-right: 20px;
		height: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.leftToolbar
	{
		margin-left: 20px;
		height: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

}

p {
	font-family: Monospace;
	font-size: 15px;
	color: white;
	z-index: 99;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	 -khtml-user-select: none; /* Konqueror HTML */
	   -moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently
								  supported by Chrome, Edge, Opera and Firefox */
	
}

.sidebar
{
	position: absolute;
	top: $topBar-height;
	bottom: 0px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	width: $sidebar-Width;
	background-color: rgba(#050a0f,.6);
	z-index: 4;

	svg
	{
		width: 50px;
		height: 50px;
	}
	.iconContainer
	{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		width: $sidebar-Width - 10;
		height: $sidebar-Width - 10;
		border-radius: 12px;
	}
}

.openDot
{
	height: 6px;
	width: 6px;
	background-color: orangered;
	border-radius: 50%;
	position: absolute;
	left: 5px;

}

.popup
{
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #050a0f;
	color: white;
	pointer-events: none;
	left: 100px;
	height: 40px;
	border-radius: 6px;
	margin: 0px;
	padding: 0px 10px 0px;
	white-space: nowrap
}

.WindowBorder
{
	position: absolute;
	z-index: 3;
	width: 1000px;
	height: 700px;
	top: 15%;
	left: 10%;
	background: #343434;
	border: #3E3E3E;
	border-style: outset;
	border-width: 2px;
	border-radius: 5px;
	overflow: hidden;


	.TopBar
	{
		border: #1B1B1B;
		background: #2A2A2A;
		border-style: none none solid none;
		border-width: 1px;
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;


		.WindowControls
		{
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			width: 125px;
		}

		.WindowButtons
		{
			display: flex;
			background:rgba(87, 87, 87, 0); //#DF4A16;
			border-radius: 50%;
			width: 25px;
			height: 25px;
			margin: 0px 5px 0px;
			align-items: center;
			justify-content: center;
			color: white;
			cursor: pointer;
		}
	}


}